<template>
  <div>

    <!-- Header -->
    <div class="grid grid-cols-2">
      <div class="justify-self-start">
        <h1>Accounts</h1>
      </div>
      <div class="justify-self-end">
        <router-link :to="`/accounts/create`" class="p-2 pr-3 pl-3 text-white font-light bg-gray-700 rounded inline-block">
          New account
        </router-link>
      </div>
    </div>

    <!-- Table -->
    <vue-good-table
      :columns="columns"
      :rows="accounts"
      :search-options="{
        enabled: true,
        placeholder: 'Search for an account...'
      }"
      :pagination-options="{
        enabled: true,
        perPage: 50,
        dropdownAllowAll: false,
        perPageDropdownEnabled: false,
      }">
    >
      <template slot="table-row" slot-scope="props">
        <router-link :to="`/accounts/${props.row.id}`" v-if="props.column.field == 'name'">
          {{ props.row.name }}
        </router-link>
        <span v-else-if="props.column.field === 'purpose'">
          {{ props.row.purpose === 'MESSAGES' ? 'Messages' : 'Scraping' }}
          <span v-if="props.row.is_blocked && props.row.purpose === 'SCRAPING'" class="bg-red-100 text-red-700 text-base font-semibold text-center rounded-lg ml-1 pl-2 pr-2 pt-1 pb-1">
            Blocked
          </span>
          <span v-else-if="!props.row.is_blocked && props.row.purpose === 'SCRAPING'" class="bg-green-100 text-green-700 text-base font-semibold text-center rounded-lg ml-1 pl-2 pr-2 pt-1 pb-1">
            Active
          </span>
        </span>
        <span v-else>
          {{props.formattedRow[props.column.field]}}
        </span>
      </template>
    </vue-good-table>

  </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      columns: [
        {
          label: 'Account',
          field: 'name'
        },
        {
          label: 'Username',
          field: 'username'
        },
        {
          label: 'Purpose',
          field: 'purpose',
          width: '180px'
        },
        {
          label: 'Created at',
          field: 'created_at',
          formatFn: value => { return this.$options.filters.formatDate(value, 'dd/MM/yyyy HH:mm'); },
          thClass: 'vgt-left-align',
          tdClass: 'vgt-left-align',
          width: '160px'
        },
        {
          label: 'Last used at',
          field: 'updated_at',
          formatFn: value => { return this.$options.filters.formatDate(value, 'dd/MM/yyyy HH:mm'); },
          thClass: 'vgt-left-align',
          tdClass: 'vgt-left-align',
          width: '160px'
        },
        {
          label: 'Expires at',
          field: 'expires_at',
          formatFn: value => { return this.$options.filters.formatDate(value, 'dd/MM/yyyy HH:mm'); },
          thClass: 'vgt-left-align',
          tdClass: 'vgt-left-align',
          width: '160px'
        },
      ],
      accounts: []
    }
  },

  created () {
    axios.get(`${process.env.VUE_APP_API_URL}/accounts`)
      .then(response => {
        this.accounts = response.data;
      })
      .catch(error => {
        console.log(error)
      })
  }
}
</script>