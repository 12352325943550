var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"grid grid-cols-2"},[_vm._m(0),_c('div',{staticClass:"justify-self-end"},[_c('router-link',{staticClass:"p-2 pr-3 pl-3 text-white font-light bg-gray-700 rounded inline-block",attrs:{"to":"/accounts/create"}},[_vm._v(" New account ")])],1)]),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.accounts,"search-options":{
      enabled: true,
      placeholder: 'Search for an account...'
    },"pagination-options":{
      enabled: true,
      perPage: 50,
      dropdownAllowAll: false,
      perPageDropdownEnabled: false,
    }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'name')?_c('router-link',{attrs:{"to":("/accounts/" + (props.row.id))}},[_vm._v(" "+_vm._s(props.row.name)+" ")]):(props.column.field === 'purpose')?_c('span',[_vm._v(" "+_vm._s(props.row.purpose === 'MESSAGES' ? 'Messages' : 'Scraping')+" "),(props.row.is_blocked && props.row.purpose === 'SCRAPING')?_c('span',{staticClass:"bg-red-100 text-red-700 text-base font-semibold text-center rounded-lg ml-1 pl-2 pr-2 pt-1 pb-1"},[_vm._v(" Blocked ")]):(!props.row.is_blocked && props.row.purpose === 'SCRAPING')?_c('span',{staticClass:"bg-green-100 text-green-700 text-base font-semibold text-center rounded-lg ml-1 pl-2 pr-2 pt-1 pb-1"},[_vm._v(" Active ")]):_vm._e()]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_vm._v(" > ")])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"justify-self-start"},[_c('h1',[_vm._v("Accounts")])])}]

export { render, staticRenderFns }